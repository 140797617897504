/**
 * This page is for logins that are accessible before logging in
 * with the exception of /login.php
 */

@require "CardFooter.mod";
@require "../styles.css";

#passwordFields #domain {
	padding-left: 37px;
	width: 59%;
	border-radius: 2px;
	border: 1px solid #acacac;
	margin-bottom: 26px;
	font-size: 20px;
	font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
	box-sizing: border-box;
}
#domain:focus {
	border: 1px solid #84bf41;
}

.dotCom {
	font-size: 20px;
	color: #acacac;
	width: 41%;
	padding-left: 5px;
	margin: 0;
	box-sizing: border-box;
	display: inline-block;

	/.login-form.zendesk & {
		display: inline-block;
		transform: translate3d(0, -50%, 0);
	}
}
#login-page {
	border-radius: 3px;
}
#company_logo {
	text-align: center;
}
#company_logo img {
	margin-top: 35px;
	text-align: center;
	width: 235px;
}
.icon.baseFillColor {
	fill: #84BF41;
}
.login-form form {
	padding-top: 34px;
	padding-bottom: 44px;
}
.login-form .instructions {
	font-size: 18px;
	font-weight: 600;
	font-size: 16px;
}

.domain-explain-wrapper .findDomain {
	font-size: 13px;
	margin-top: 15px;
	color: #555;
	margin-bottom: 18px;
	line-height: 18px;
}

.btn.btnAction,
.btn.btnAction:visited {
	background-color: #84BF41;
	border-color: #75AC36;
	height: 36px;
	font-size: 20px;
}
.btn.btnAction:hover,
.btn.btnAction:focus,
.btn.btnAction:active {
	background-color: #75ac36;
	border-color: #649829;
	background-image: linear-gradient(0deg, #75ac36, #84bf41);
}
.front .login-pane {
	border-top: 1px solid #dcdcdc;
	border-bottom: 4px solid #84bf41;
}
.domain-explain-wrapper {
	padding: 34px 40px 37px;
	background-color: #fff;
	border-top: 1px solid #dcdcdc;
	border-bottom: 4px solid #84bf41;
	border-radius: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}
.domain-explain-wrapper p {
	font-size: 18px;
	color: #222222;
	font-weight: 400;
	margin-bottom: 18px;
}

.domain-explain-wrapper .whatis-header {
	margin-bottom: 20px;
	font-family: BhrHeaderFontStack;
	font-weight: 500;
	font-size: 21px;
}

.PreLogin {
	// .PreLogin__body
	&__body {
		// .PreLogin__body--centered
		&--centered {
			text-align: center;
		}
	}

	// .PreLogin__row
	&__row {
		fab-color: gray9;
		fab-font-size: medium;
		fab-line-height: medium;
		margin: 15px 0 20px;
	}
}
